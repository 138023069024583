<script>
import store from '@store'

export default {
  metaInfo() {
    return {
      title: "",
    }
  },
  data() {
    return {
      messages: [],
      username: '',
      password: '',
      tryingToLogIn: false,
    }
  },
  methods: {
    tryToLogIn() {
      this.tryingToLogIn = true
      this.messages = []
      return store.dispatch('logIn', {
        username: this.username,
        password: this.password,
      })
          .then(() => {
            console.log(store)
            this.tryingToLogIn = false
            // Redirect to the originally requested page, or to the home page
            this.$router.push(this.$route.query.redirectFrom || {name: 'dashboard'})
          })
          .catch((error) => {
            this.tryingToLogIn = false
            console.log(error)
            if (error.response.status === 404) {
              this.messages.push({
                closable: true, severity: "error", content: "Email ou mot de passe incorrect"
              })
            } else if (error.response.status === 401) {
              this.messages.push({
                closable: true, severity: "error", content: "Mot de passe incorrect"
              })
            } else {
              this.messages.push({
                closable: true, severity: "error", content: "Erreur inconnue"
              })
            }
          })
    },
  },
}
</script>

<template>

  <div class="surface-0 flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
    <div class="grid justify-content-center p-2 lg:p-0" style="min-width:80%">
      <div class="col-12 mt-5 xl:mt-0 text-center mb-7">
        <img :src="'../../images/jmf_logo.jpg'">
      </div>
      <div class="col-12 xl:col-5" style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, var(--primary-color), rgba(33, 150, 243, 0) 30%);">
        <div class="h-full w-full m-0 py-7 px-4" style="border-radius:53px; background: linear-gradient(180deg, var(--surface-50) 38.9%, var(--surface-0));">
          <div class="text-center mb-5">
            <div class="text-900 text-3xl font-medium mb-3">Bienvenue !</div>
            <span class="text-600 font-medium">Connectez vous pour continuer</span>
          </div>
          <Message v-for="msg of messages" :closable="msg.closable" :severity="msg.severity" :key="msg.content">
            {{ msg.content }}
          </Message>
          <form v-on:submit.prevent="tryToLogIn">
            <div class="w-full md:w-10 mx-auto">
              <label for="email1" class="block text-900 text-xl font-medium mb-2">Nom d'utilisateur</label>
              <input id="email1" v-model="username" type="text" class="p-inputtext p-component p-filled w-full mb-3 w-full mb-3" placeholder="Nom d'utilisateur" style="padding:1rem;" />

              <label for="password1" class="block text-900 font-medium text-xl mb-2">Mot de passe</label>

              <input type="password" v-model="password" class="form-control w-full mb-3 p-inputtext p-component p-password-input w-full p-password-input w-full"
                     :placeholder="'Mot de passe'" id="password1"/>
              <!--<div class="flex align-items-center justify-content-between mb-5">
                <div class="flex align-items-center">
                  <Checkbox id="rememberme1" v-model="checked" :binary="true" class="mr-2"></Checkbox>
                  <label for="rememberme1">Remember me</label>
                </div>
                <a class="font-medium no-underline ml-2 text-right cursor-pointer" style="color: var(--primary-color)">Forgot password?</a>
              </div>-->
              <button label="Se connecter" class="w-full p-3 text-xl p-button p-component w-full p-3 text-xl"><span class="p-button-label">Connexion</span></button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- end app-wrap -->
</template>
